







































































































































































































































































































































































































































































































































































































































	@import "../../assets/css/antd.css";
.firefighting-chart{
	width: 33%; 
	height: 230px; 
	float: left;
}
.firefighting-header{
	width: 45px; 
	height: 30px; 
	line-height: 30px;
	text-align: center;
	float: left;
}
